.img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.imgWrapper {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
  padding-bottom: 5%;
}

.overlayImg {
  position: absolute;
  top: .15%;
  left: -28%;
  width: 156%;
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  pointer-events: none;
}

.circular {
  border-radius: 50%;
}

.delegateViewCircular {
  border-radius: 50%;
  margin-top: 13px;
}

.circleWithBorder {
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.rounded {
  border-radius: 15px;
}

.circularNounWrapper {
  width: 42px;
  height: 42px;
}

.delegateViewCircularNounWrapper {
  width: 42px;
  height: 42px;
  margin-left: 12px;
}




@media (max-width: 1200px) {
  .circularNounWrapper {
    height: 70%;
    width: 70%;
  }
}