.heading {
  display: inline-block;
  font-size: 3rem;
  font-family: 'Londrina Solid';
  font-size: 75px;
  margin-bottom: 10px;
  text-align: center;
}

.bodyText {
  display: inline-block;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  margin-top: 10%;
}

.textWrapper {
  display: block;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.nounContentCol {
  display: flex;
}

.nounWrapper {
  align-self: flex-end;
  width: 100%;
  z-index: 0;
}