.altWrapper {
  text-align: center;
}

.bidCollection {
  display: grid;
  text-align: start;
  list-style-type: none;
  row-gap: 0.5rem;
  padding: 4px 6px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: black;
  border-radius: 12px !important;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: beige;
  outline: solid 3px !important;
  box-shadow: none !important;
  font-family: 'Londrina Solid';
  padding: 10px 16px;
  font-size: 25px;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.bidRowCool {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.8rem;
  border-bottom: 2px solid var(--brand-cool-border);
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bidRowWarm {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.25rem 0.75rem 0.5rem 0.75rem;
  border-bottom: 2px solid var(--brand-warm-border);
  font-size: 0.95rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bidRowWarm a {
  color: var(--brand-color-red) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.bidRowCool a {
  color: var(--brand-color-blue) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.bidRowWarm a:hover,
.bidRowCool a:hover {
  filter: brightness(110%);
}

.bidRowCool:hover,
.bidRowWarm:hover {
  filter: brightness(105%);
}

.bidItem {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}

.leftSectionWrapper {
  display: flex;
  flex-direction: row;
  width: 25%;
  padding-left: 5%;
  padding-right: 5%;
}

.midSectionWrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  padding-left: 5%;
  padding-right: 5%;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.nounsterId {
  display: flex;
  line-height: 23px;
  font-family: 'Londrina Solid';
  font-size: 1.2rem;
  justify-content: center;
  padding-left: 50%;
}

.rightSectionWrapper {
  display: flex;
  width: 100%;
  justify-content: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.burnDestination {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
  max-width: 992px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.bidder {
  font-family: 'PT Root UI';
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bidder>div>div {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.bidRowCool .bidder>div>div {
  color: var(--brand-cool-dark-text);
}

.bidRowWarm .bidder>div>div {
  color: var(--brand-warm-dark-text);
}

.bidDate {
  display: none;
  color: gray;
}

.bidRowCool .bidAmount {
  color: var(--brand-cool-dark-text);
}

.bidRowWarm .bidAmount {
  color: var(--brand-warm-dark-text);
}

.linkSymbol {
  color: var(--brand-cool-light-text);
  padding: 1px;
}

.linkSymbol:hover {
  color: var(--brand-cool-dark-text);
  padding: 1px;
}

@media (max-width: 992px) {
  .bidRow {
    border-radius: 10px;
  }

  .bidAmount .linkSymbol .bidder .burnDestination .nounsterId {
    font-size: 16px;
  }
}