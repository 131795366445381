.roadmapSection {
  display: grid;
  padding: 4rem 0 !important;
}

.phaseBlockActive {
  border-radius: 10px;
  font-size: 32px;
  line-height: 36px;
  padding: 12px 12px 12px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid black;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 125px;
  background-color: whitesmoke;
  font-family: 'Londrina Solid';
}

.phaseBlockInactive {
  border-radius: 10px;
  font-size: 28px;
  color: rgba(0, 0, 0, 0.2);
  line-height: 28px;
  padding: 12px 12px 12px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: 5%;
  margin-right: 5%;
  max-width: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  font-family: 'Londrina Solid';
}

.ellipses {
  border: 4px solid black;
  border-radius: 50%;
  padding: 3px;
  background-color: black;
  margin: 4px;
}

.ellipsesBlock {
  height: 40px;
  transition: all 0.125s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 75px;
}

.wrapper {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  height: 100%;
  width: 100%;
}

.iosSection {
  padding: 0 !important;
}

.textWrapper {
  padding-right: 2rem;
}

.textWrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

.textWrapper p {
  font-size: 1.2rem;
}

.embedContainer {
  padding: 36% 0 0 0;
  position: relative;
}

.embedContainer iframe {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 350px;
}

.videoSubtitle {
  position: absolute;
  top: 365px;
}

.videoSubtitle a {
  display: inline-block;
  text-decoration: underline;
  color: #6c757d;
  padding: 0;
}

.iosImgContainer {
  padding: 0;
  position: relative;
}

.iosImg {
  max-width: 100%;
  height: 35rem;
  bottom: 0;
}

.dlFromAppStoreImg {
  margin-top: 2rem;
  width: 10rem;
}

@media (max-width: 992px) {

  .roadmapSection {
    display: flex;
  }

  .videoSection {
    padding: 0 !important;
  }

  .iosSection {
    padding-bottom: 2rem;
  }

  .textWrapper {
    padding: 2rem;
  }

  .textWrapper h1 {
    font-size: 3.75rem;
  }

  .embedContainer {
    padding: 56.25% 0 0 0 !important;
    margin-bottom: 60px;
  }

  .embedContainer iframe {
    top: 0;
    height: 100%;
  }

  .videoSubtitle {
    top: unset;
    bottom: -40px;
    padding: 0 2.5rem;
  }

  .iosImgContainer {
    display: none;
  }
}

@media (max-width: 576px) {
  .videoSubtitle {
    bottom: -50px;
  }
}