.wrapper {
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 12px !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  word-wrap: normal;
}

.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 68px;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .wrapper h1 {
    font-size: 56px;
  }
}